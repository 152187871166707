import React from 'react'
import { Row } from '../'

import Toggle from 'react-toggle'
import '../../../node_modules/react-toggle/style.css'

const ToggleWrapper = (props) => {
	const {
		label,
		labelClass,
		toggleValue,
		onChange,

		id,
		wrapperClass,
	} = props
	return (
		<div className={wrapperClass} style={{ zIndex: '1' }}>
			<Row>
				<label className={`${labelClass}`} htmlFor={id}>
					{label}
				</label>
			</Row>
			<Toggle checked={toggleValue} icons={false} onChange={onChange} id={id} />
		</div>
	)
}

export default ToggleWrapper
