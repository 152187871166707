import React from 'react'
import { Header2, HomePageLinks } from '../../components'
import AnnouncementList from './AnnouncementList'

const Announcements = () => {
	return (
		<div className='dark:bg-main-dark-bg  bg-main-bg dark:text- font-opensans mt-20'>
			<Header2 links={<HomePageLinks />} />

			<div className='dark:text-romanDarkTextActive text-romanLightTextActive text-xl md:text-3xl text-center justify-center items-center p-4'>
				Important Announcements
			</div>

			<AnnouncementList />
		</div>
	)
}

export default Announcements
